// main.js
/*import barba from '@barba/core';
import gsap from 'gsap';*/

import AOS from 'aos';

var Flickity = require('flickity');
require('flickity-imagesloaded');

const hamburger = document.querySelector('.hamburger');
const primaryNavigation = document.querySelector('.primary-navigation');
const primaryNavigationVideo = document.getElementById('nav-video')

hamburger.addEventListener('click', function() {
   hamburger.classList.toggle('is-active');
   primaryNavigation.classList.toggle('is-active');
   document.body.classList.toggle('scroll-fixed');

   if(primaryNavigation.classList.contains('is-active')) {
       primaryNavigationVideo.play()
   } else {
       primaryNavigationVideo.pause()
   }
});

if(document.getElementsByClassName('carousel').length > 0) {
    var flkty = new Flickity( '.carousel', {
        draggable: true,
        contain: true,
        groupCells: 3,
        imagesLoaded: true,
        wrapAround: true,
        freeScroll: true,
        pageDots: true,
        prevNextButtons: false
    });

    setTimeout(function(){
        flkty.resize();
    }, 350);
}

if(document.getElementsByClassName('services-carousel').length > 0) {
    var sflkty = new Flickity( '.services-carousel', {
        draggable: true,
        contain: true,
        groupCells: 1,
        imagesLoaded: true,
        wrapAround: false,
        freeScroll: true,
        pageDots: true,
        prevNextButtons: false
    });

    setTimeout(function(){
        sflkty.resize();
    }, 550);
}

if(document.getElementsByClassName('project-image-slider').length > 0) {
    var proj_flkty = new Flickity( '.project-image-slider', {
        draggable: true,
        contain: false,
        imagesLoaded: true,
        wrapAround: true,
        freeScroll: false,
        pageDots: true,
        prevNextButtons: false
    });

    setTimeout(function(){
        proj_flkty.resize();
    }, 550);
}

if(document.getElementsByClassName('article-slider').length > 0) {
    var art_flkty = new Flickity( '.article-slider', {
        draggable: true,
        contain: false,
        imagesLoaded: true,
        wrapAround: true,
        freeScroll: false,
        pageDots: true,
        prevNextButtons: false
    });

    setTimeout(function(){
        art_flkty.resize();
    }, 550);
}

if(document.getElementsByClassName('page-image-slider').length > 0) {
    var pi_slider = new Flickity( '.page-image-slider', {
        draggable: true,
        contain: false,
        imagesLoaded: true,
        wrapAround: true,
        freeScroll: false,
        pageDots: true,
        prevNextButtons: false
    });

    setTimeout(function(){
        pi_slider.resize();
    }, 350);
}

let scrollToTopTarget = document.querySelector('footer');
let scrollToTopBtn = document.querySelector('#scrollToTopBtn');

scrollToTopBtn.addEventListener('click', function() {
    window.scrollTo(0,0);
})


let lastKnownScrollPosition = 0;
let ticking = false;

function hideAgencyLogo(scrollPos) {
    if(scrollPos > window.innerHeight / 8) {
        document.getElementById('logo').style.opacity = 0;
    } else {
        document.getElementById('logo').style.opacity = 1;
    }
}

document.addEventListener('scroll', function(e) {
    lastKnownScrollPosition = window.scrollY;

    if (!ticking) {
        window.requestAnimationFrame(function() {
            hideAgencyLogo(lastKnownScrollPosition);
            ticking = false;
        });

        ticking = true;
    }
});

window.addEventListener('load', function() { AOS.init({
    once: true,
    easing: 'ease-in-quart'
}) });

