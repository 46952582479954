import "core-js/stable"
import "regenerator-runtime/runtime"
import "/src/js/site-modules"
import "/src/js/lion-head"
import "/src/js/buttons"
import "./app.scss"
import bodymovin from "lottie-web/build/player/lottie"
/*
  To include modules, import and include them anywhere in your JS. For example,
  uncommenting the following two lines will force the jquery chunk to be
  generated. Normally, only code that is actually used will be output as modules.
*/

const lottieInstances = document.querySelectorAll('.service-single-slide');
let lottieAnimations = [];

if(lottieInstances && lottieInstances.length > 0) {
  lottieInstances.forEach((lottieInstance, index) => {
    var animation = bodymovin.loadAnimation({
      container: lottieInstance.querySelector('.lottie-player'), // Required
      path: lottieInstance.getAttribute('data-lottie-url'), // Required
      renderer: 'svg', // Required /canvas/html
      loop: false, // Optional
      autoplay: false, // Optional
      name: "lottieAnim_" + index, // Name for future reference. Optional.
    })
    lottieInstance.setAttribute('data-lottie-anim', animation.name);

    lottieAnimations.push(animation);
  });
}

if( document.querySelector('.services-single-article div.lottie-player') ) {
  const el = document.querySelector('.services-single-article div.lottie-player');
  const headerAnimation = bodymovin.loadAnimation({
    container: el, // Required
    path: el.getAttribute('data-lottie-url'), // Required
    renderer: 'svg', // Required /canvas/html
    loop: false, // Optional
    autoplay: true, // Optional
    name: "lottieAnimHeader", // Name for future reference. Optional.
  })
}

function handleIntersection(entries) {
  entries.map((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('active');
      bodymovin.play(entry.target.getAttribute('data-lottie-anim'));
      /*lottieAnimations.forEach((lottieAnimation) => {
        lottieAnimation.play();
        console.log('is intersecting');
      });*/
    } else {
      entry.target.classList.remove('active');
      /*lottieAnimations.forEach((lottieAnimation) => {
        lottieAnimation.stop();
      });*/
    }
  });
}

if(document.querySelector('.service-single-slide')) {
  const target = document.querySelectorAll('.service-single-slide');
  let options = {
    root: document.querySelector('.block--services'),
    rootMargin: '0px',
    threshold: 1.0
  }
  const observer = new IntersectionObserver(handleIntersection);

  target.forEach(singleSlide => observer.observe(singleSlide));
}

// Only used in dev
if (module.hot) {
  module.hot.accept();
}
