import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { RoughnessMipmapper } from 'three/examples/jsm/utils/RoughnessMipmapper';
import { gsap } from "gsap";
import { ExpoScaleEase, SlowMo } from "gsap/EasePack";
import { CustomEase } from "gsap/CustomEase";

gsap.registerPlugin(CustomEase, ExpoScaleEase, SlowMo);

let camera, scene, renderer;
let model, mixer, clips, clip, action;
let plane, raycaster, mouse, pointOfIntersection;
let timer, currSeconds, once = 0;
let renderProcess;

let clock = new THREE.Clock();
const container = document.createElement( 'div' );
const canvasContainer = document.querySelector('.block.block--hero');


if(document.querySelector('.block.block--hero') && document.querySelector('.block.block--hero').getAttribute('data-gltf') !== "1") {

    container.classList.add('lion-head');
    document.querySelector('.block.block--hero').prepend( container );

    const onScreen = new Set();
    const intersectionObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                onScreen.add(entry.target);
            } else {
                onScreen.delete(entry.target);
            }
        });
        console.log(onScreen.size
            ? `on screen: ${[...onScreen].map(e => e.textContent).join(', ')}`
            : 'none');

        if(!onScreen.size) {
            cancelAnimationFrame(renderProcess);
        } else {
            renderProcess = requestAnimationFrame(render);
        }
    });

    document.querySelectorAll('.lion-head').forEach(elem => {
        intersectionObserver.observe(elem);
    });

    init();
}

function init() {
    camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 0.1, 50 );
    camera.position.set( 0, 0, 0.2 );
    scene = new THREE.Scene();
    scene.background = new THREE.Color( 0xD62130 );

    new RGBELoader()
        .setPath( 'lionhead/' )
        .load( 'royal_esplanade_1k.hdr', function ( texture ) {

            texture.mapping = THREE.EquirectangularReflectionMapping;
            scene.environment = texture;
            const roughnessMipmapper = new RoughnessMipmapper( renderer );

            const loader = new GLTFLoader().setPath( 'lionhead2/' );
            loader.setDRACOLoader(new DRACOLoader().setDecoderPath('js/libs/drago/gltf'))
            loader.load( 'LION_ANIMATIONS.gltf', function ( gltf ) {
                /*gltf.scene.traverse( function ( child ) {
                    if ( child.isMesh ) {
                        roughnessMipmapper.generateMipmaps( child.material );
                    }
                } );*/

                mixer = new THREE.AnimationMixer(gltf.scene);
                clips = gltf.animations;

                model = gltf.scene;
                model.scale.set(4, 4, 4); // 0, 0 , 0
                scene.add( model );

                roughnessMipmapper.dispose();

                plane = new THREE.Plane(new THREE.Vector3(0, 0, -1), -30);
                raycaster = new THREE.Raycaster();
                mouse = new THREE.Vector2();
                pointOfIntersection = new THREE.Vector3();

                gsap.to( model.scale, {
                    duration: 2,
                    x: 6,
                    y: 6,
                    z: 6
                } );

                renderer.domElement.addEventListener("mousemove", onMouseMove, false);

                clip = THREE.AnimationClip.findByName( clips, '01_EYES' );
                action = mixer.clipAction( clip );
                // action.setLoop(THREE.LoopOnce)
                action.play();

                /*mixer.addEventListener('finished', () => {
                    action.reset();
                    console.log('play normal');
                    clip = THREE.AnimationClip.findByName( clips, '01_EYES' );
                    action = mixer.clipAction( clip );
                    action.play();
                })*/

                // render();
            } );
        } );

    renderer = new THREE.WebGLRenderer( {
        antialias: false,
        alpha: false
    } );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( window.innerWidth, window.innerHeight / 100 * 95 );
    /*renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1;*/
    renderer.domElement.style.opacity = 0;
    renderer.outputEncoding = THREE.sRGBEncoding;
    container.appendChild( renderer.domElement );

    gsap.to(renderer.domElement, {
                    duration: 3,
                    opacity: 1,
                })

    window.addEventListener( 'resize', onWindowResize );
}

function resetTimer() {

    /* Clear the previous interval */
    clearInterval(timer);

    /* Reset the seconds of the timer */
    currSeconds = 0;
    once = 0;

    /* Set a new interval */
    timer = setInterval(startIdleTimer, 10000);
}

function startIdleTimer() {
    currSeconds++
    if(currSeconds >= 5 && once === 0) {
        console.log('more than 10: play IDLE');

        clip = THREE.AnimationClip.findByName( clips, '03_IDLE' );
        action = mixer.clipAction( clip );
        action.setLoop(THREE.LoopOnce);
        action.play();

        once++;
    } else {
        clip = THREE.AnimationClip.findByName( clips, '02_LOOK' );
        action = mixer.clipAction( clip );
        action.setLoop(THREE.LoopOnce);
        action.play();
    }
}

function onMouseMove(event){
    resetTimer();
    mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
    mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);
    raycaster.ray.intersectPlane(plane, pointOfIntersection);
    pointOfIntersection.x *= 10;
    pointOfIntersection.y *= 6;
    pointOfIntersection.z = 850;
    model.lookAt(pointOfIntersection);
}

function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize( window.innerWidth, window.innerHeight );
    render();
}

function render() {
    renderProcess = requestAnimationFrame(render)

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    if(mixer) {
        mixer.update(clock.getDelta());
    }
    renderer.render( scene, camera );

    console.log('render')
}
